import React from 'react';
import { APIReferenceItems } from '../utils/APIReference';
import ReferencePage from '../components/common/ReferencePage';
import { readFileSync } from 'fs';
import { replaceRoutes } from '../utils/mapRoutes';

export function getServerData() {
  let type = 'tsx';
  
  const sideNav = readFileSync(`./src/utils/sideNavItems.json`, 'utf8');
  const sideNavItem = JSON.parse(sideNav);

  const route = readFileSync(`./src/docs-config/src/constants/routes.json`, 'utf8');
  const routes = JSON.parse(route);

  const sideNavItems = replaceRoutes(sideNavItem, routes);

  return {
    props: {
    clientSideNavList: sideNavItems || [],
    type: type,
    }
  };
}

const APIReference: React.FC = () => {
  return (
    <ReferencePage
      title="References"
      description="Manage your data, your vaults, and your account with Skyflow's APIs."
      items={APIReferenceItems}
    ></ReferencePage>
  );
};

export default APIReference;
